import React from "react";
import { useTranslation } from "react-i18next";
import Typing from "../design-system/Typing";
import ProductTimeline from "./ProductTimeline";

export default function Registration() {
  const { i18n } = useTranslation();

  React.useEffect(() => {
    i18n.changeLanguage(navigator.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
      }}
    >
      <Typing text="DB Delay" />

      <ProductTimeline />
    </div>
  );
}
