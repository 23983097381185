import React, { createContext, useState } from "react";
import { UserContextDefault, UserContextType } from "../interfaces/UserContext";
import useAuth from "./useAuth";
import { AxiosResponse } from "axios";
import { IUser } from "@jozys/db-delay-types";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

export const UserContext = createContext<UserContextType>(UserContextDefault);

export default function UserProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [user, setUser] = useState<IUser>();
  const { i18n } = useTranslation();
  const auth = useAuth();

  /**
   * Fetches the user data from the backend using the auth data from ory
   */
  const fetchAuthorizedUser = async () => {
    if (auth.getToken() == null) return;
    const response: AxiosResponse<IUser> = await global.axios.get(`/user`, {
      headers: { Authorization: `Bearer ${auth.getToken()}` },
    });
    setUser(response.data);
    i18n.changeLanguage(response.data.language);
    moment.locale(response.data.language);
  };

  /**
   * Fetches a user by its id from the backend server
   * @param id the uuid from the user
   * @returns
   */
  const getUserById = async (id: string) => {
    const response = await global.axios.get<AxiosResponse<IUser>>(
      `/user/${id}`,
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    return response.data.data as IUser;
  };

  React.useEffect(() => {
    fetchAuthorizedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.getToken()]);

  return (
    <UserContext.Provider
      value={{
        fetchAuthorizedUser,
        user,
        getUserById,
      }}
    >
      <LocalizationProvider
        adapterLocale={user?.language ?? "en"}
        dateAdapter={AdapterMoment}
      >
        {props.children}
      </LocalizationProvider>
    </UserContext.Provider>
  );
}
