import { IJourneyDetailed } from "@jozys/db-delay-types";
import { Check, Error, FlagCircleOutlined, Warning } from "@mui/icons-material";
import { Card, CardContent, Container, Tooltip, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { parseSize } from "../../create-journey/utils/size";
import DelayInfo from "../design-system/DelayInfo";

export interface DelayCardProps {
  journey?: IJourneyDetailed;
}

export default function DelayCard(props: DelayCardProps) {
  const { journey } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Card sx={{ background: theme.palette.background.default, mt: 1 }}>
      <CardContent>
        <Container sx={{ ml: 1 }}>
          <DelayInfo
            text={`${t("journeys.delay.plannedArrival")}: ${moment(
              journey?.endDate
            )
              .format("dddd DD.MM.YYYY HH:mm")
              .toString()}`}
            icon={<FlagCircleOutlined />}
          />
          <DelayInfo
            text={`${t("journeys.delay.realArrival")}: ${moment(
              journey?.endDate
            )
              .add(journey?.delay, "minutes")
              .format("dddd DD.MM.YYYY HH:mm")
              .toString()}`}
            icon={
              <FlagCircleOutlined
                color={journey && journey?.delay > 5 ? "error" : "success"}
              />
            }
          />
          <DelayInfo
            text={
              <>
                {/*  fontSize={
                  window.innerWidth < 500
                    ? parseSize("medium", 0.5)
                    : parseSize("medium", 0.75)
                }
              > */}
                {t("journeys.delay.title")}{" "}
                <span
                  style={{
                    color:
                      journey && journey?.delay > 5
                        ? theme.palette.error.main
                        : theme.palette.success.main,
                    fontSize:
                      window.innerWidth < 500
                        ? parseSize("medium", 0.5)
                        : parseSize("medium", 0.75),
                    fontWeight: "bold",
                  }}
                >
                  {journey?.delay}
                </span>{" "}
                {t("settings.minutes")}
              </>
            }
            icon={<Warning />}
          />
          <DelayInfo
            icon={
              journey && journey.delay > 5 ? (
                <Error color="error" />
              ) : (
                <Check color="success" />
              )
            }
            text={
              <Tooltip title={t("journeys.delay.dbDefinition")}>
                <span>
                  {t("journeys.delay.onTime")}
                  {
                    <span style={{ fontWeight: "bold" }}>
                      {journey && journey.delay > 5
                        ? t("settings.no")
                        : t("settings.yes")}
                    </span>
                  }
                </span>
              </Tooltip>
            }
          />
        </Container>
      </CardContent>
    </Card>
  );
}
