import { Card, Container, Typography, useTheme } from "@mui/material";
import { IStatistic } from "../interface/IStatistic";
import { parseSize } from "../../create-journey/utils/size";

export interface StatisticCardProps {
  statistic: IStatistic<string>;
  onClick?: () => void;
}

export default function StatisticCard(props: StatisticCardProps) {
  const theme = useTheme();
  return (
    <Card
      onClick={props.onClick}
      sx={{
        background: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "15vh",
        width: "15vh",

        borderRadius: theme.spacing(3),
      }}
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          flex: 4,
        }}
      >
        <Typography
          sx={{
            lineBreak: "revert",
            display: "flex",
            flex: 3,
            textWrap: "balance",
            whiteSpace: "wrap",
            maxWidth: "70%",
            fontSize:
              window.innerWidth < 600 || window.innerHeight < 1000
                ? parseSize("medium", 0.5)
                : parseSize("medium", 0.75),
          }}
          color={"textPrimary"}
          fontWeight="bold"
          variant="subtitle1"
        >
          {props.statistic.key}
        </Typography>
        <Container sx={{ display: "flex", flex: 3, alignContent: "center" }}>
          {props.statistic.icon}
        </Container>
      </Container>
      <Typography
        variant="h6"
        sx={{
          flex: 1,
          color: props.statistic.color ?? theme.palette.text.primary,
          fontWeight: "bold",
          fontSize:
            window.innerWidth < 600
              ? parseSize("medium", 0.5)
              : parseSize("medium", 0.75),
        }}
      >
        {props.statistic.value}
      </Typography>
    </Card>
  );
}
