import { Train as ITrain } from "@jozys/db-api-wrapper/dist/src/types/connection";
import { DelayType, IDelayData, TrainType } from "@jozys/db-delay-types";
import { Card, Container, useTheme } from "@mui/material";
import moment from "moment";
import Train from "../../core/design-system/Train";
import ConnectedDestinations from "./ConnectedDestinations";
import DelayInput from "./DelayInput";

export interface DetailedTrainInformationProps {
  train: ITrain;
  handleDelayEdit?: (data: IDelayData) => void;
  disabled?: boolean;
  elevation?: number;
}

export default function DetailedTrainInformation(
  props: DetailedTrainInformationProps
) {
  const theme = useTheme();
  const getDisplayName = () => {
    const attributes = [
      props.train.displayName,
      props.train.destination,
      props.train.operator,
    ];
    let string = "";
    for (const attr of attributes) {
      string += `${attr} ${
        attributes.indexOf(attr) < attributes.length - 1 ? "| " : " "
      }`;
    }
    return string;
  };
  return (
    <Card
      elevation={props.elevation ?? 1}
      sx={{
        m: 1,
        display: "flex",
        flexDirection: "column",
        pb: 1,
        background: theme.palette.background.default,
      }}
    >
      <ConnectedDestinations size="medium" {...props.train} />
      <Container
        sx={{
          alignSelf: "center",
          width: "75%",
          m: 1,
        }}
      >
        <Train
          {...props.train}
          portion={1}
          type={props.train.category.toUpperCase() as TrainType}
          displayName={getDisplayName()}
          start
          end
        />
      </Container>
      <Container sx={{ mt: 1 }}>
        <DelayInput
          readOnly={props.disabled}
          handleChange={(time, reason) => {
            if (props.handleDelayEdit)
              props.handleDelayEdit({
                delay: Math.abs(
                  moment(props.train.arrivalTime).diff(time, "minutes")
                ),
                reason: reason?.id as DelayType,
              });
          }}
          train={props.train}
        />
      </Container>
    </Card>
  );
}
