import { Connection } from "@jozys/db-api-wrapper/dist/src/types/connection";
import { IJourneyDetailed, IJourneyPreview } from "@jozys/db-delay-types";
import { AxiosResponse } from "axios";
import React, { createContext } from "react";
import useAuth from "../../auth/hooks/useAuth";
import {
  JourneyContextDefault,
  JourneyContextType,
} from "../interfaces/JourneyContext";

export const JourneyContext = createContext<JourneyContextType>(
  JourneyContextDefault
);

export type JourneyPagination = IJourneyPreview[];

export default function JourneyProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const auth = useAuth();
  const [journeys, setJourneys] = React.useState<JourneyPagination[]>([]);

  const fetchJourneys = async (page = 0) => {
    const response: AxiosResponse<IJourneyPreview[]> = await axios.get(
      `/journey?page=${page}`,
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    let updateJourneys = [...journeys];
    updateJourneys[page] = response.data;
    setJourneys(updateJourneys);

    return response.data;
  };

  const getPageAmount = async (amountPerPage: number) => {
    const response = await axios.get(`/journey/pages?amount=${amountPerPage}`, {
      headers: { Authorization: `Bearer ${auth.getToken()}` },
    });
    return response.data;
  };

  const getLastJourney = () => {
    if (journeys && journeys[0] && journeys.length > 0) {
      return journeys[0][0];
    }
    return null;
  };

  const searchDBJourney = async (
    from: string,
    to: string,
    date: Date
  ): Promise<Connection[]> => {
    const response = await axios.post(
      `/db/journeys`,
      {
        from,
        to,
        date: date.toISOString(),
      },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );
    return response.data ?? [];
  };

  const getJourneyById = async (id: string): Promise<IJourneyDetailed> => {
    const response: AxiosResponse<IJourneyDetailed> = await axios.get(
      `/journey/${id}`,
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` },
      }
    );

    return response.data;
  };

  return (
    <JourneyContext.Provider
      value={{
        getPageAmount,
        fetchJourneys,
        journeys,
        getLastJourney,
        searchDBJourney,
        getJourneyById,
      }}
    >
      {props.children}
    </JourneyContext.Provider>
  );
}
