import { Typography } from "@mui/material";
import React from "react";
import { parseSize } from "../../create-journey/utils/size";

export interface DelayInfoProps {
  icon: React.ReactNode;
  text: React.ReactNode | string;
}

export default function DelayInfo(props: DelayInfoProps) {
  return (
    <Typography
      textAlign={"left"}
      sx={{
        display: "flex",
        p: 1,
        alignItems: "center",
        /*    fontSize:
          window.innerWidth < 500
            ? parseSize("medium", 0.5)
            : parseSize("medium", 0.), */
      }}
    >
      {props.icon}
      <span
        style={{
          marginLeft: "4px",
          fontSize:
            window.innerWidth < 500
              ? parseSize("medium", 0.5)
              : parseSize("medium", 0.7),
        }}
      >
        {props.text}{" "}
      </span>
    </Typography>
  );
}
