import { IUser } from "@jozys/db-delay-types";

export type UserContextType = {
  user: IUser | undefined;
  fetchAuthorizedUser: () => void;
  getUserById: (id: string) => Promise<IUser>;
};

export const UserContextDefault: UserContextType = {
  fetchAuthorizedUser: () => new Promise(() => {}),
  getUserById: () => new Promise(() => {}),
  user: undefined,
};
