import { createTheme, Theme } from "@mui/material";
import { deDE } from "@mui/x-date-pickers/locales";
import "@fontsource/titillium-web";
import "@fontsource/bungee";

const theme = (dark: boolean): Theme => {
  if (dark) {
    return darkTheme;
  } else {
    return lightTheme;
  }
};

const lightTheme = createTheme(
  {
    palette: {
      primary: {
        light: "#89231F",
        main: "#89231F",
        dark: "#884E49",
      },
      secondary: {
        main: "#1f8589",
      },
      tonalOffset: {
        light: 0.55,
        dark: 0.4,
      },
      background: {
        default: "#f5f5f5",
        paper: "#dfdfdf",
      },
      success: {
        main: "#508B2B",
      },
      error: {
        main: "#EC0016",
      },
      mode: "light",
    },
    typography: {
      allVariants: {
        color: "#282d37",
      },
    },
  },
  deDE
);

const darkTheme = createTheme({
  palette: {
    secondary: {
      main: "#1f8589",
    },
    primary: {
      light: "#89231F",
      main: "#89231F",
      dark: "#884E49",
    },
    background: {
      paper: "#2a2a2a",
      default: "#3f3f3f",
    },
    text: {
      primary: "#dfdfdf",
      secondary: "#ffffff",
    },
    success: {
      main: "#518912",
    },
    error: {
      main: "#f98c8f",
    },
    mode: "dark",
  },
  typography: {
    fontFamily: "Titillium Web, Bungee",
    allVariants: {
      color: "#dfdfdf",
    },
  },
});
export default theme;
