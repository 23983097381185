import { Connection } from "@jozys/db-api-wrapper/dist/src/types/connection";
import { Station } from "@jozys/db-api-wrapper/dist/src/types/station";
import {
  IDelayData,
  IJourneyDetailed,
  IJourneyPreview,
  TrainType,
} from "@jozys/db-delay-types";
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Container,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddDelayData from "./AddDelayData";
import FindJourney from "./FindJourney";
import ReviewJourney from "./ReviewJourney";
import createJourney from "../utils/createJourney";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/hooks/useAuth";
import { Passenger } from "./AddPassengers";
import { uniq } from "lodash";
import { mapStringToDelay } from "../utils/delayReasons";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export default function CreateJourney() {
  const { t } = useTranslation();
  const auth = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [index, setSelectedIndex] = useState<number>(-1);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [startStation, setStartStation] = useState<Station | undefined>();
  const [endStation, setEndStation] = useState<Station | undefined>();
  const [newJourney, setNewJourney] = useState<DeepPartial<IJourneyPreview>>();
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState<{
    message: string | number;
    variant: string;
  }>();
  const navigate = useNavigate();

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const constructNewJourney = () => {
    const selectedConnection = connections[index];
    setNewJourney({
      startStation: selectedConnection.from,
      endStation: selectedConnection.to,
      endDate: selectedConnection.endDate,
      startDate: selectedConnection.startDate,
      date: selectedConnection.startDate,
      passengers: [],
      others: [],
      delay: selectedConnection.delay,
      trains: selectedConnection.trains.map((t) => ({
        id: t.id,
        arrival: t.arrivalTime,
        departure: t.departureTime,
        date: t.arrivalTime,
        delay: {
          delay: t.delay?.delay ?? 0,
          reason: mapStringToDelay(t.delay?.reason)?.id,
        },
        line: parseInt(t.displayName.split(" ")[1] ?? t.number),
        displayName: t.displayName,
        type: t.category as TrainType,
        operator: t.operator,
        destination: t.destination,
        from: t.from,
        to: t.to,
        toId: t.toId,
        fromId: t.fromId,
        portion: Math.round((t.duration / selectedConnection.duration) * 100),
      })),
    });
  };

  const isDisabled = () => {
    switch (activeStep) {
      case 0:
        return index === -1;
      case 1:
        return newJourney === undefined;
      case 2:
      default:
        return false;
    }
  };

  const addPassengers = (newPassengers: Passenger[]) => {
    setPassengers(uniq(newPassengers));
  };

  const addOthers = (newOthers: string[]) => {
    setNewJourney({ ...newJourney, others: newOthers });
  };

  const updateNewJourney = (data: IDelayData, index: number) => {
    const old = newJourney;
    if (old === undefined || old.trains === undefined) return;
    const trains = old.trains.map((t, i) => {
      if (i !== index) return t;
      return {
        ...t,
        delay: data,
      };
    });
    setNewJourney({ ...old, trains });
  };

  const getComponent = () => {
    switch (activeStep) {
      case 0: // Find a connection for your journey
        return (
          <FindJourney
            selectedIndex={index}
            setSelectedIndex={(i) => {
              setSelectedIndex(i);
            }}
            setJourneys={(journeys) => setConnections(journeys)}
            journeys={connections}
            endStation={endStation}
            startStation={startStation}
            setEndStation={setEndStation}
            setStartStation={setStartStation}
          />
        );
      case 1: // Add delay data for that connection
        return (
          <AddDelayData
            handleDelayData={(data, index) => updateNewJourney(data, index)}
            setDelay={(delay: number) => {
              setNewJourney({ ...newJourney, delay });
            }}
            connection={connections[index]}
          />
        );
      case 2:
        return (
          <ReviewJourney
            addPassengers={addPassengers}
            addOthers={addOthers}
            passengers={passengers}
            journey={newJourney}
          />
        );
    }
  };

  React.useEffect(() => {
    if (index !== -1) constructNewJourney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <Container sx={{ minWidth: "400px", mt: 1 }}>
      <Stepper activeStep={activeStep}>
        <Step key={"chooseJourney"}>
          <StepLabel>{t("journeys.creation.steps.find")}</StepLabel>
        </Step>
        <Step key={"addDelay"}>
          <StepLabel>{t("journeys.creation.steps.delay")}</StepLabel>
        </Step>
        <Step key={"validateInput"}>
          <StepLabel>{t("journeys.creation.steps.validate")}</StepLabel>
        </Step>
      </Stepper>
      {
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {t("journeys.creation.steps.step")} {activeStep + 1}
          </Typography>
          {getComponent()}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="info"
              disabled={activeStep === 0}
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              {t("journeys.creation.steps.back")}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              color="primary"
              disabled={isDisabled()}
              onClick={async () => {
                if (activeStep < 2) {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                  //@ts-ignore
                  const result = await createJourney(
                    //@ts-ignore
                    {
                      ...newJourney,
                      startStation: startStation?.id,
                      endStation: endStation?.id,
                      //@ts-ignore
                      passengers: passengers.map((v) => v.id),
                    } as IJourneyDetailed,
                    auth.getToken() as string
                  );
                  if (
                    typeof result.message === "string" &&
                    result.variant === "success"
                  ) {
                    navigate(`/journeys/${result.message}`);
                    return;
                  }
                  setSnackbarDetails(result);
                  setSnackbarOpen(true);
                }
              }}
            >
              {activeStep === 2
                ? t("journeys.creation.steps.finish")
                : t("journeys.creation.steps.next")}
            </Button>
          </Box>
        </React.Fragment>
      }
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={
            (snackbarDetails?.variant as AlertProps["severity"]) ?? "error"
          }
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarDetails?.variant}
        </Alert>
      </Snackbar>
    </Container>
  );
}
