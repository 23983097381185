import { TrainCategory } from "@jozys/db-api-wrapper/dist/src/types/connection";
import {
  IDelayData,
  IJourneyDetailed,
  IJourneyPreview,
} from "@jozys/db-delay-types";
import { ArrowDownward } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  useTheme,
} from "@mui/material";
import JourneyPreview from "../../journey/components/Journey";
import DetailedTrainInformation from "./DetailedTrainInformation";
import Transition from "./Transition";

export interface IternaryProps {
  journey: IJourneyDetailed;
}

export default function Iternary(props: IternaryProps) {
  const theme = useTheme();
  return (
    <Accordion sx={{ mt: 1, background: theme.palette.background.default }}>
      <AccordionSummary
        sx={{ m: 0, p: 0, pr: 4 }}
        expandIcon={<ArrowDownward />}
      >
        <div style={{ width: "100%" }}>
          <JourneyPreview
            showDate
            showIconButton={false}
            journey={props.journey as IJourneyPreview}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {props.journey?.trains &&
          props.journey.trains.map((t, i) => {
            if (t === undefined) return <></>;
            return (
              <Container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <DetailedTrainInformation
                  disabled
                  //@ts-ignore
                  train={{
                    ...t,
                    category: t.type?.toUpperCase() as TrainCategory,
                    arrivalTime: t.arrival as Date,
                    departureTime: t.departure as Date,
                    delay: t.delay as IDelayData,
                    //@ts-ignore
                    destination: t.destination,
                    //@ts-ignore
                    displayName: t.displayName,
                    //@ts-ignore
                    operator: t.operator,
                    //@ts-ignore
                    duration: t.duration,
                    to: t.endStationId,
                    from: t.startStationId,
                  }}
                />
                {props.journey!.trains!.length > 0 &&
                  i <= props.journey!.trains!.length - 2 && (
                    <Container sx={{ width: "50%", m: 1 }}>
                      <Transition
                        //@ts-ignore
                        nextDepartureTime={
                          props.journey!.trains![i + 1]?.departure
                        }
                        //@ts-ignore
                        previousArrivalTime={new Date(t.arrival!.toString())}
                      />
                    </Container>
                  )}
              </Container>
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
}
