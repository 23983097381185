import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StatisticCard from "../design-system/StatisticCard";
import { IStatistic } from "../interface/IStatistic";

export interface StatisticsPreviewProps {
  statistics: IStatistic<string>[];
}

export default function StatisticsPreview(props: StatisticsPreviewProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: window.innerWidth < 500 ? "100%" : "50%",
      }}
    >
      <Typography textAlign={"left"} variant="h5">
        {t("statistics.view")}
      </Typography>
      <div
        style={{
          display: "flex",
          alignContent: "space-between",
          flexFlow: "row wrap",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        {props.statistics.map((stat: IStatistic<string>) => (
          <div style={{ marginTop: 10, flexBasis: "25%" }}>
            <StatisticCard
              onClick={() => navigate("/statistics")}
              statistic={stat}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
